<template>
  <div>
    <div class="card card-custom bgi-no-repeat bg-white gutter-b" :style="`background-position: center center; background-size: 70% auto; background-image: url(/media/svg/shapes/${bgShape})`">
      <div class="card-body p-0">
        <div class="d-flex flex-row-reverse align-items-center">

          <div class="card card-custom bg-light card-shadowless m-10 min-w-180px">
            <div class="card-body py-10 px-10  ribbon ribbon-right">
              <div class="ribbon-target shadow-none bg-light text-danger font-weight-bolder rounded-left-0 rounded-top-right rounded-bottom-left" style="top: 0px; right: 0px;"> <inline-svg src="media/svg/shapes/tcmb.svg" style="width: 40px; height: 40px;"/></div>
              <div class="mb-8">
                <span class="card-title font-weight-bolder text-dark-50 font-size-h6 mb-2 d-block">Alış Fiyatı</span>
                <div class="d-flex flex-row align-items-center">
                  <span class="text-dark-75 font-weight-bolder display-4">{{ tcmbBuy }}</span>
                </div>
              </div>
              <div>
                <span class="card-title font-weight-bolder text-dark-50 font-size-h6 mb-2 d-block">Satış Fiyatı</span>
                <span class="text-dark-75 font-weight-bolder display-4">{{ tcmbSell }}</span>
              </div>
            </div>
          </div>

          <div class="px-10 flex-fill">
            <span class="card-title font-weight-bolder text-dark-50 font-size-h6 mb-4 d-block">
              <span class="label label-rounded label-md font-size-h6 font-weight-bold mr-2" :class="`label-${currencyColor}`">{{ currencySign }}</span>{{ currencyName }}
            </span>
            <span class="text-dark-75 font-weight-bolder display-2 d-flex align-items-center">{{ exchangeRate }}</span>
            <span class="card-title font-weight-bolder text-dark-50 font-size-h6 d-flex align-items-center">1 {{ currencyAbbr }} <span class="label label-light-warning label-lg label-pill label-inline font-weight-bolder font-size-h6 ml-6">{{ margin }}</span></span>
            <button class="btn btn-primary btn-lg font-weight-bolder px-2 px-lg-10" @click="openModal()">Düzenle</button>
          </div>

        </div>
      </div>
    </div>

    <b-modal :id="`modal-set-exchange-rate-${currencyAbbr}`" :ref="`modal-set-exchange-rate-${currencyAbbr}`" no-fade centered header-class="py-3" footer-class="py-3"  @ok="handleModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-2">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <h5>{{ currencyName }}</h5>
          </div>
        </div>
      </template>
      
      <div class="form-group">
        <label class="text-body font-weight-bold pl-1">Döviz Kuru</label>
        <div class="input-group" :class="{'input-group-solid': modal.isAuto}">
          <div class="input-group-prepend"><span class="input-group-text font-weight-bolder">TRY</span></div>
          <input type="number" class="form-control form-control-lg" :readonly="modal.isAuto" v-model="modal.price">
        </div>
      </div>

      <div class="form-group">
        <label class="checkbox checkbox-dark checkbox-pill font-size-lg font-weight-bold py-3 rounded mb-0">
          <input type="checkbox" v-model="modal.isAuto"/>
          <span class="mr-2"></span>
          TCMB döviz kurunu kullan
        </label>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel"
          >İptal et</button>

          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            :disabled="modal.price.length === 0 || modal.price <= 0"
            @click="ok"
          >
            Kaydet
          </button>
        </div>
      </template>

    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from '@/core/services/api.service';

export default { 
  props: {
    currency: String,
    tcmbBuy: Number,
    tcmbSell: Number,
    exchangeRate: Number,
    isManuelRate: Boolean,
    bgShape: String,
    crossRate: Number
  },
  data() {
    return {
      modal: {
        isAuto: !this.isManuelRate,
        price: this.exchangeRate
      }
    }
  },
  computed: {
    ...mapGetters(["siteConfig"]),

    currencyName() {
      return this.siteConfig(`currency.${this.currency}.name`);
    },
    currencySign() {
      return this.siteConfig(`currency.${this.currency}.sign`);
    },
    currencyAbbr() {
      return this.siteConfig(`currency.${this.currency}.abbr`);
    },
    currencyColor() {
      return this.siteConfig(`currency.${this.currency}.color`);
    },
    margin() {
      if (!this.isManuelRate) {
        return 'TCMB Kuru';
      } else {
        return '% ' + Math.abs(100 * (this.exchangeRate - this.tcmbBuy) / this.exchangeRate).toFixed(2);
      }
    },
    isAuto() {
      return !this.isManuelRate;
    }
  },
  watch: {
    'modal.isAuto': function(val) {
      if (val) {
        this.modal.price = this.tcmbBuy;
      }
    }
  },
  methods: {
    openModal() {
      this.modal.price = this.exchangeRate;
      this.modal.isAuto = !this.isManuelRate;
      this.$bvModal.show(`modal-set-exchange-rate-${this.currencyAbbr}`);
    },
    handleModalOk() {
      const postData = {
        currency: this.currencyAbbr,
        rate: this.modal.price,
        isAuto: this.modal.isAuto ? 1 : 0,
        crossRate: this.crossRate
      }

      ApiService.post('exchange/set.req.php', postData)
      .then(() => {
        this.$emit('change');
      })
      .catch(({ response }) => {
        alert(response.data.errorMessage);
      });

    }
  }
}
</script>

<style>

</style>