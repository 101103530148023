<template>
  <div>
    <div class="d-flex flex-row align-items-center justify-content-between mb-10">
      <div class="d-flex flex-column">
        <h1 class="font-weight-bolder text-dark my-2 mr-5">
          Döviz Kurları
        </h1>
      </div>
    </div>
      

    <div class="row">
      <div class="col-lg-6">
        <CurrencyItem
          currency="USD"
          :tcmb-buy="usd.tcmbBuy"
          :tcmb-sell="usd.tcmbSell"
          :exchange-rate="usd.exchangeRate"
          :is-manuel-rate="usd.isManuelRate"
          :cross-rate="crossRate"
          bg-shape="dollar-rotate.svg"
          @change="get"
          v-if="usd !== null"
        />
      </div>
      <div class="col-lg-6">
        <CurrencyItem
          currency="EUR"
          :tcmb-buy="eur.tcmbBuy"
          :tcmb-sell="eur.tcmbSell"
          :exchange-rate="eur.exchangeRate"
          :is-manuel-rate="eur.isManuelRate"
          :cross-rate="crossRate"
          bg-shape="euro-rotate.svg"
          @change="get"
          v-if="eur !== null"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="card card-custom bg-light gutter-b">
          <div class="card-body p-0">
            <div class="d-flex flex-row-reverse align-items-center">

              <div class="max-w-350px pr-10">
                <p class="font-size-lg">
                  <strong>Alış, Satış</strong> ve <strong>Çapraz Kur</strong> bilgileri <strong>{{ date }}</strong> günü saat <strong>15:30</strong>'da belirlenen 
                  Gösterge Niteliğindeki Türkiye Cumhuriyet Merkez Bankası kurlarıdır.
                </p>
              </div>

              <div class="px-10 flex-fill mt-8">
                <span class="card-title font-weight-bolder text-dark-50 font-size-h6 mb-4 d-block"><span class="label label-rounded label-warning label-md font-size-h6 font-weight-bold mr-2">x</span>Çapraz Kur</span>
                <span class="text-dark-75 font-weight-bolder display-4">{{ crossRate }}</span>
                <span class="card-title font-weight-bolder text-dark-50 font-size-h6 d-block">EUR / USD</span>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import CurrencyItem from "@/view/pages/exchange/components/CurrencyItem";

export default {
  components: {
    CurrencyItem
  },
  data() {
    return {
      crossRate: null,
      date: null,
      usd: null,
      eur: null,
      modal: {
        price: ''
      }
    }
  },
  mounted() {
    this.get()
  },
  methods: {
    get() {
      ApiService.get('exchange/get.req.php')
      .then(({ data }) => {
        this.usd = data.exchange.usd;
        this.eur = data.exchange.eur;
        this.crossRate = data.exchange.crossRate;
        this.date = data.exchange.date;
      })
      .catch(({ response }) => {
        alert(response.data.errorMessage);
      });
    }
  }
}
</script>

<style>

</style>